<template>
    <filter-list
        v-model="list"
        @init="init"
        :sortKey="sortKey"
        ref="list"
        url="/technicalManager/all"
        :filters="filters"
        :hasAction="false"
        :defaultQuery="defaultQuery"
    >
        <div class="list_fanter">
            <template v-for="item in list">
                <manage-gride :key="item.id" :info="item"></manage-gride>
            </template>
        </div>
    </filter-list>
</template>

<script>
import ManageGride from '../../components/list/ManageGride.vue';
import FilterList from '../../components/page/FilterList.vue';
export default {
    components: { FilterList, ManageGride },
    data() {
        return {
            fieldOptions: [], //领域,
            list: [],
            sortKey: [
                // {
                //     name: '成交时间',
                //     key: 'createdAt'
                // },
                // {
                //     name: '成交价格',
                //     key: 'soldPrice'
                // },
                // {
                //     name: '浏览量',
                //     key: 'view'
                // }
            ],
            defaultQuery: {
                isExpert: false
            }
        };
    },
    created() {
        this.init();
    },
    computed: {
        filters() {
            return [
                {
                    name: '专业领域',
                    key: 'field',
                    list: [...this.fieldOptions]
                }
            ];
        }
    },
    methods: {
        init() {
            this.$http
                .post('/setting/byFlag', { flag: 1 })
                .then(res => {
                    if (res.length > 0) {
                        this.fieldOptions = res.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            };
                        });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$message.error(e.error);
                });
        }
    }
};
</script>

<style lang="less" scoped>
.list_fanter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
</style>
