<template>
    <div class="list_con">
        <div class="manage" :class="{ 'manage-mini': size === 'mini' }">
            <!-- <router-link class="manage" :class="{ 'manage-mini': size === 'mini' }"> -->
            <div class="manage_top">
                <el-image :src="getImg(info.avatar)" fit="fill"></el-image>
                <div class="manage_top_content">
                    <div class="name">{{ info.name }}</div>
                    <div class="go">
                        擅长领域：
                        <span v-for="(item, index) in info.fieldName" :key="index" class="go_field">{{ item }}</span>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div style="margin-bottom: 10px">联系方式：{{ info.phone }}</div>
                <div>任职单位：{{ info.work }}</div>
            </div>
            <!-- <div class="content"> -->
            <!-- <div class="tags">
                <el-tag size="mini" :key="index" v-for="(item, index) in info.fieldName" effect="plain">{{
                    item
                }}</el-tag>
                <el-tag size="mini" effect="plain">电子信息</el-tag>
            </div> -->

            <!-- <div class="edit-btn" v-if="isEdit">
                <el-button v-if="info.status === 'PASS'" type="primary" size="small" plain @click.prevent="edit"
                    >编辑</el-button
                >
                <el-button v-if="info.status === 'PENDING'" type="primary" size="small" plain>审核中</el-button>
                <el-button v-if="info.status === 'DENY'" type="danger" size="small" plain>已拒绝</el-button>
                <el-button size="small" plain @click.prevent="del">删除</el-button>
            </div>
            <div v-else>
                <div class="btn" v-if="size !== 'mini'">
                    <el-button type="primary" class="icon-button" @click.prevent="connectKefu" size="small" plain>
                        <i class="iconfont iconfont-nav_icon_xiaoxi1"></i>
                        咨询客服
                    </el-button>
                    <el-button type="primary" @click.prevent="postMessage" class="icon-button" size="small" plain>
                        <i class="iconfont iconfont-nav_icon_xiaoxi"></i>提交留言
                    </el-button>
                </div>
            </div> -->
            <!-- </div> -->
            <!-- </router-link> -->
        </div>
    </div>
</template>

<script>
import comEvent from '../../mixins/comEvent';
export default {
    props: {
        info: {
            type: Object,
            default: () => {
                return {};
            }
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: 'normal'
        },
        fromHome: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        // path() {
        //     let query = {
        //         id: this.info.id
        //     };
        //     if (this.fromHome) {
        //         query.fromHome = true;
        //     }
        //     return {
        //         name: this.info.expert ? 'expertDetail' : 'technicalManagerDetail',
        //         query: query
        //     };
        // }
    },
    mixins: [comEvent],
    methods: {
        edit() {
            this.$router.push({
                path: '/technicalEdit',
                query: {
                    id: this.info.id
                }
            });
        },
        del() {
            this.$alert('删除将无法恢复，确认要删除么？', '警告', { type: 'error' })
                .then(() => {
                    return this.$http.post(`/technicalManager/del/${this.info.id}`);
                })
                .then(() => {
                    this.$message.success('删除成功');
                    this.$emit('refreash');
                })
                .catch(e => {
                    if (e !== 'cancel') {
                        console.log(e);
                        this.$message.error((e || {}).error || '删除失败');
                    }
                });
        }
    }
};
</script>

<style lang="less" scoped>
.list_con {
    width: 50%;
    padding: 10px 20px 0px !important;
    box-sizing: border-box;
}
.manage {
    // width: 460px;
    height: 210px;
    background: #ffffff;
    border: 1px solid rgba(56, 85, 142, 0.04);
    padding: 24px 20px 0;
    display: block;
    // .flex-col();
    // align-items: stretch;
    box-sizing: border-box;
    cursor: pointer;
    // display: inline-block;
    // margin: 10px 10px 10px 14px;

    &:hover {
        box-shadow: 0px 3px 12px 0px rgba(81, 81, 81, 0.1);
        border: 1px solid #01a041;
        // box-shadow: 0px 3px 12px 0px rgba(255, 135, 0, 0.2);
        // border: 1px solid #ff8700;
    }
}
.manage_top {
    display: flex;
    padding-bottom: 16px;
    border-bottom: 1px solid #f2f3f5;
    box-sizing: border-box;
    .el-image {
        width: 56px;
        height: 56px;
        border-radius: 8px;
        flex-shrink: 0;
        margin-right: 12px;
    }
}
// .content {
//     .flex-col();
//     align-items: center;
//     padding: 14px 0 10px;
// }

.tags {
    max-width: 100%;
    flex-wrap: nowrap;
    .ellipsis();
    // .flex();
    .el-tag + .el-tag {
        margin-left: 10px;
    }
    margin-top: 8px;
    color: @prim;
}
.edit-btn {
    .flex();
    margin: 10px 0;
    .el-button {
        &:first-child {
            width: 88px;
        }

        &:last-child {
            width: 56px;
        }
    }
}
.name {
    font-size: 16px;
    font-weight: bold;
    color: #292c33;
    line-height: 26px;
    margin-bottom: 8px;
}

.go {
    // text-align: center;
    font-size: 13px;
    color: #878d99;
    line-height: 22px;
    display: flex;
    flex-wrap: wrap;
    height: 64px;
    overflow-y: auto;
    .go_field {
        height: 22px;
        background: rgba(1, 160, 65, 0.08);
        border-radius: 1px;
        padding: 0px 6px;
        box-sizing: border-box;
        color: #01a041;
        line-height: 22px;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    // margin-top: 6px;
    // height: 48px;
    // .ellipsis();
    // max-width: 100%;
}
.btn {
    .flex();
    padding: 10px 0;
}
.icon-button {
    height: 26px;
    padding: 0 6px;
    i {
        font-size: 20px;
    }

    /deep/ i {
        font-size: 18px;
    }
}
.bottom {
    font-size: 13px;
    color: #bcc1cc;
    line-height: 18px;
    // padding-top: 4px;
    // margin-top: 5px;
    align-self: stretch;
    margin: 12px 0px;
    // text-align: center;
}
.manage-mini {
    width: 204px;
    height: 240px;
    padding: 12px 12px 0;
    .el-image {
        width: 112px;
        height: 112px;
        margin: auto;
        display: block;
    }

    .content {
        padding: 12px 0 10px;
    }
}
</style>
